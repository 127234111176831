@use 'styles/variables' as *;

.bottomNavigator{
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: $top-shadow;

  .navItem{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .backDropPad{
      z-index: -4;
      position: absolute;
      bottom: 0;
      width: 32px;
      height: 4px;
      background-color: $yellow;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .navIconWrapper{
      height: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: #7a7a7a !important;

      .navLabel{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }

      .backDropCircle{
        z-index: -4;
        position: absolute;
        opacity: 0.6;
        width: 16px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: $yellow;
      }

      &.selected{
        color: $black3 !important;
      }
    }
  }
}