.logout-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  padding: 32px;
}
