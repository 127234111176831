@use 'styles/variables' as *;
@import 'styles/report';

.label{
  @include poppin-font($color: $gray2);
  word-break: break-all;
}

.value{
  @include poppin-font();
  word-break: break-all;
}

.statusLabel::after{
  content: "";
  display: inline-block;
  margin-left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100vh;
  background-color: $green2;
}

.productCard{
  width: 100%;
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  grid-template-rows: 100%;

  & > *{
    height: 100%;
  }
}

.thumbnailSection{
  display: flex;
  column-gap: 32px;

  .thumbnail{
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }

  .thumbnailData{
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .skuCode {
      @include poppin-font($weight: $bold6);
      word-break: break-all;

      .fbvIcon{
        height: 12px;
        margin-left: 4px;
      }
    }
  }

  &.mobile{
    column-gap: 20px;
    padding-right: 16px;

    .thumbnail{
      width: 52px;
      height: 52px;
      border-radius: 8px;
    }

    .thumbnailData{
      row-gap: 8px;
    }
  }
}

.cardColumn{
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-left: 32px;
}

.expandProductCard{
  width: 100%;

  :global(.MuiPaper-root){
    border: none !important;
    box-shadow: none !important;
    background-color: unset !important;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;

    :global(.MuiAccordionSummary-gutters){
      padding: 0 !important;
    }
    :global(.MuiAccordionDetails-root){
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .cardGrid{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 12px;
    column-gap: 16px;
  }
}