@use 'styles/variables' as *;

.headerWrapper{
  width: 100%;
  height: 52px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $bottom-shadow;

  .label{
    font-size: 14px;
    font-weight: 600;
    color: $black3;
  }
}

.layoutWrapper{
  width: 100%; 

  .linkList{
    padding: 16px;
    .linkItem{
      height: 60px;
      color: $black3;
      img{
        height: 28px;
        margin-right: 16px;
      }
      .rightArrow{
        color: #7a7a7a;
      }
    }
  }
}