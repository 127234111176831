@use 'styles/variables' as *;
@import 'styles/report';

.badge {
  position: absolute;
  padding: 6px 3px 6px 12px;
  color: white;
  font-weight: $bold6;
  font-size: 12px;
  border-radius: 15px 0px 0px 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  &.outOfStockBadge{
    background-color: #ED546F;
  }

  &.lowStockBadge{
    background-color: #FF9A22;
  }
}