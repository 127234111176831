@use 'styles/variables' as *;

.headerWrapper{
  width: 100%;
  margin-top: 24px;
  margin-bottom: 12px;
  padding: 0px 24px;
  flex-shrink: 0;
  @include flex-box;

  .label{
    max-width: 50%;
    @include poppin-font($weight: $bold6, $size: 20px);
    display: flex;
    align-items: center;

    .arrowBack, .angleBack{
      margin-right: 18px;
      cursor: pointer;
    }
    .angleBack{
      font-size: 14px;
    }
  }

  .rightSection{
    @include flex-box;
    column-gap: 8px;
  }

}


@media screen and (max-width: 600px){

  .headerWrapper{
    background-color: $white;
    margin-top: 0;
    padding: 8px 16px;
    box-shadow: $bottom-shadow;

    .label{
      font-size: 14px;
    }
  }
}