.custom-pie-legend-el{
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.custom-pie-legend-el .color-code{
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.custom-pie-legend-el .label-section{
  margin-left: 8px;
}
.label-section .l1, .label-section .l2{
  margin: 0;
  font-size: 12px;
  color: '#7A7A7A';
}
.label-section .l2{
  font-weight: 600;
  color: '#272727';
}
