@use 'styles/variables' as *;

.previewModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(800px, 100dvw);
  background-color: #fff;
  outline: none;
  border-radius: 4px;
  padding: 16px;

  .body{
    $availWidth: calc(min(800px, 100dvw) - 32px);
    $availHeight: calc((9/16) * $availWidth);
    width: 100%;
    height: $availHeight;
    background-color: $black;
    @include flex-box($justify: center);

    img{
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .footer{
    width: 100%;
    @include flex-box($justify: flex-end);

    .closeBtn{
      padding: 4px 20px;
      border-radius: 4px;
      margin-top: 16px;
      text-transform: none;
    }
  }
}


@media screen and (max-width: 600px) {
  .previewModal{
    .body{
      height: unset !important;
      max-height: 80dvh;
    }
  }
}