@use 'styles/variables' as *;

.navWrapper{
  position: relative;
  margin-top: $forge-header-height;
  width: 100dvw;
  height: calc(100dvh - $forge-header-height);
  display: grid;
  grid-template-columns: $side-bar-width calc(100% - $side-bar-width);
  grid-template-rows: 100%;

  .sideBarSection{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .layoutSection{
    overflow-y: scroll;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

@media screen and (max-width: 900px) {
  .navWrapper{
    grid-template-columns: 100%;
    grid-template-rows: calc(100% - $bottom-nav-height) $bottom-nav-height;

    .sideBarSection{
      display: none;
    }

    .layoutSection{
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    .bottomNavSection{
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    &.fullHeight{
      grid-template-rows: 100%;

      .bottomNavSection{
        display: none;
      }
    }
  }
}