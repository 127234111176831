@use "styles/variables" as *;

.multi-select-filter{
  height: 32px;
  @include flex-box;
  background-color: $white;
  border: 1px solid $gray5;
  box-shadow: $bottom-shadow;
  border-radius: 100vmax;
  width: max-content;

  .popover-style{
    @include popover-style($margin: 18px 0 0 0);
  }

  @include filter-options-section;

  .filter-label{
    @include filter-option-label;
    &.submit-button-box{
      background-color: $white;
      cursor: initial;
      border-radius: 0px 0px 8px 8px;

      button{
        width: 100%;
        height: 20px;
        @include poppin-font($size: 10px, $color: $white, $weight: 600);
      }
    }
  }

  .multi-select-button{
    padding: 0 12px;
    @include flex-box;
    cursor: pointer;
    gap: 4px;
    max-width: 200px;

    p{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include poppin-font($color: $gray2);
      color: $gray2;
      span{
        color: $black3;
      }
    }
  }
}