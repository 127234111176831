@use 'styles/variables' as *;
@import 'styles/report';
@import 'styles/classes';


.statusCell{
  display: flex;
  align-items: center;
  gap: 8px;

  .cautionIcon{
    color: $red;
    font-size: 15px;
  }

  .viewBtn{
    margin: 6px 0;
    padding: 4px 12px !important;
    border-radius: 8px;
    border: 1px solid $gray2;
    font-size: 10px;
    margin-left: auto;
    font-weight: $bold6;
  }
}

.issueTxt{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: $bold6;
}

.issueWrapper{
  margin: 0 24px 12px 24px;
  padding: 32px;
  width: calc(100% - 2 * 24px);
  overflow-y: hidden;
  border-radius: 10px;
  flex-grow: 1;
  background-color: $white;
  font-size: 12px;

  .mainContent{
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .statusSection{
      @include flex-box($align: flex-start);
      gap: 20px;

      .leftSection, .rightSection{
        @include flex-box;
      }
      .leftSection {
        column-gap: 8px;

        .cautionIcon{
          color: $red;
          font-size: 15px;
        }
      }
      .rightSection{
        column-gap: 20px;
      }
    }

    .labelValueBlock{
      margin-bottom: 20px;
    }

    .label{
      @include poppin-font($color: $gray2);
    }

    .value{
      @include poppin-font($color: $black3, $size: 14px);
    }

    .imgList{
      padding-top: 8px;
      @include flex-box($justify: flex, $wrap: wrap);
      gap: 12px;
      img{
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    .remarkBlock{
      padding: 12px;
      background-color: $gray4;
      border-radius: 8px;

      .label{
        margin-bottom: 10px !important;
      }
    }

    .satisfactionStatus{
      .label{
        @include poppin-font($color: $gray2, $size: 10px);
      }
      .actionBtns{
        margin-top: 8px;
        @include flex-box($justify: flex-start);
        column-gap: 16px;

        .btn{
          padding: 6px 12px;
          text-transform: none;
          font-size: 10px;
          font-weight: $bold6;
          border-radius: 6px;
          svg{
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .issueWrapper{
    margin: 0;
    width: 100%;
    padding: 40px 16px 16px 16px;
    border-radius: 0;

    .mainContent{
      .statusSection{
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.downloadFAB{
  img{
    width: 20px !important;
  }
}