@use "styles/variables" as *;

.search-by-filter{
  height: 32px;
  @include flex-box;
  background-color: $white;
  border: 1px solid $gray5;
  box-shadow: $bottom-shadow;
  border-radius: 100vmax;

  .popover-style{
    @include popover-style($margin: 18px 0 0 0);
  }

  .filter-label{
    @include filter-option-label;
  }

  .search-by-wrapper{
    display: flex;
  }

  .search-type{
    width: max-content;
    height: 100%;
    padding: 0 12px;
    @include flex-box;
    border-right: 1px solid #E5E5E5;
    cursor: pointer;
    gap: 4px;

    p{
      font-size: 12px;
    }
  }
  .search-text{
    height: 100%;
    width: 200px;
    @include flex-box;
    padding: 0 12px;
    gap: 8px;

    input{
      color: $black3;
      font-size: 12px;
      width: 148px;
      border: none;
      outline: none;

      &::placeholder {
        color: $gray2;
      }

      &:disabled{
        background-color: $white;
      }
    }
    p{
      cursor:default;
      width: 148px;
      @include poppin-font($color: $gray2);
    }

    .search-icon{
      cursor: pointer;
      color: $gray2;
    }
  }
}


.search-selection-wrapper{
  width: 100%;
  padding: 0 24px;
  position: relative;
  @include flex-box($justify: flex-start);
  margin-bottom: 12px;

  .search-selections{
    position: relative;
    overflow-x: scroll;
    @include flex-box($justify: flex-start);
    font-size: 10px;
    color: $gray2;
    column-gap: 8px;

    .selection{
      @include flex-box($justify: flex-start);
      background-color: $white;
      border-radius: 100vmax;
      padding: 4px 7.5px;
      column-gap: 4px;
      flex-shrink: 0;

      .value{
        color: $black3;
      }

      .search-icon, .clear-icon{
        font-size: 15px !important;
      }
      .clear-icon{
        cursor: pointer;
      }
    }

    -ms-overflow-style: none;
    scrollbar-width: 0;
    &::-webkit-scrollbar{
      display: none;
    }
  }

  .all-clear{
    cursor: pointer;
    margin-left: 20px;
    color: $red2;
    font-size: 10px;
  }
}