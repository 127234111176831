@use 'styles/variables' as *;
@import 'styles/classes';
@import 'styles/report';

.paymentSummaryWrapper{
  width: calc(100% - 2 * 24px);
  margin: 0 24px 12px 24px;

  .payment-summary{
    padding: 20px 16px;
    background-color: $white;
    box-shadow: 0px 1px 15px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 10px;
  
    .individual-payment{
      @include flex-box($dir: column-reverse, $align: unset);
      row-gap: 6px;

      .payment-value{
        font-size: 14px;
        margin-bottom: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 600px){
  .paymentSummaryWrapper{
    flex-shrink: 0;
    width: 100%;
    margin: 0 0 12px 0;
    padding: 1px 24px 1px 24px;
    overflow-x: scroll;

    .payment-summary{
      width: max-content;

      .individual-payment{
        flex-basis: unset !important;
        flex-grow: unset !important;
        width: max-content;
      }
    }
  }
}

.disabledTooltip{
  width: 300px !important;
}


.popupWrapper{
  @include popover-style($margin: 12px 0 0 0);
}

.actionButton{
  @include poppin-font;
  cursor: pointer;
  padding: 8px 12px;
  border-bottom: 1px solid $gray4;

  &:last-of-type{
    border-bottom: none;
  }
}

.downloadActions{
  min-height: 40px;
  @include flex-box;
  column-gap: 12px;

  :global(MuiDivider-root){
    height: 10px;
  }

  .downloadCTA{
    @include flex-box;
    column-gap: 8px;
    cursor: pointer;

    img{
      max-width: 12px;
    }

    p{
      @include poppin-font($weight: 600, $size: 10px, $color: $darkBlue);
    }

    &.disabled{
      opacity: 0.3;
    }
  }
}

.tooltipTitle{
  @include poppin-font($size: 10px);
}

