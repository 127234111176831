@use 'styles/variables' as *;

.policyModal{
  width: 800px;
  height: 600px;
  padding: 32px;
  border-radius: 16px;


  .container{
    width: 100%;
    height: 100%;
    @include flex-box($dir: column);
    row-gap: 20px;

    iframe{
      width: 100%;
      flex-grow: 1;
      border: none;
    }

    .footer{
      width: 100%;
      @include flex-box($dir: column, $align: center);
      p{
        width: 100%;
        text-align: left;
        @include poppin-font($size: 14px, $weight: $bold6);
        margin-bottom: 20px;
      }
      button{
        padding: 10px 40px;
        text-transform: none;
        font-size: 14px;
        font-weight: $bold6;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .policyModal{
    width: 90vw;
    height: 90vh;
    padding: 16px;
    border-radius: 8px;
  }
}