@use 'styles/variables' as *;
@import 'styles/report';
@import 'styles/classes';

.reportDownload {
  font-weight: $bold6 !important;
  font-size: 12px !important;
  height: max-content;
}

.generateSingleBtn {
  font-weight: $bold6 !important;
  font-size: 12px !important;
  flex-shrink: 0;
  height: max-content;
}

.generateMultipleBtn {
  font-weight: $bold6 !important;
  font-size: 12px !important;
  flex-shrink: 0;
  height: max-content;
  border-radius: 6px;
  background-color: #3361ff;
  &:hover {
    background-color: #2e58e8;
  }
}

.bottomBar {
  justify-content: space-around !important;
  align-items: center;
}

.bottomBarWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
