@use 'styles/variables' as *;

.red{
  color: $red !important;
}

@mixin pill-base-style(){
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: $bold6;
  width: max-content;
  font-size: 12px;
}

.orange-pill {
  @include pill-base-style();
  background-color: $light-orange;
  color: $orange;
}

.light-green-pill {
  @include pill-base-style();
  background-color: $light-green;
  color: $green;
}

.green-pill {
  @include pill-base-style();
  background-color: $light-green2;
  color: $green2;
}

.gray-pill {
  @include pill-base-style();
  background-color: $light-gray;
  color: $gray;
}

.red-pill {
  @include pill-base-style();
  background-color: $lightRed;
  color: $red;
}