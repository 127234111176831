@use 'styles/variables' as * ;

.txt{
  font-size: 16px !important;
  color: $gray2 !important;
  line-height: 2;
}

.link{
  font-weight:  $bold6 !important;
  color: $darkBlue  !important;
  line-height: 2;
  text-decoration: none !important;
}

.icon{
  color: $gray2;
  font-size: 20px;
  height: 20px;
}

.footerWrapper{
  flex-shrink: 0;
  width: 100dvw;
  min-height: 80px;
  @include flex-box($justify: center);
  background-color: $gray4;

  .container{
    width: 100%;
    max-width: 1200px;
    height: 100%;
    padding: 16px 24px;
    @include flex-box;

    .leftSection{
      @include flex-box;
      column-gap: 40px;
      
      .vaareeInfo{
        @include flex-box;
        column-gap: 12px;

        img{
          height: 32px;
        }
      }
      .mailLink{
        @include flex-box;
        column-gap: 4px;
      }
    }

    .rightSection{
      @include flex-box;
      column-gap: 20px;
    }
  }
}


@media screen and (max-width: 1200px) {
  .socialLinkTxt{
    display: none;
  }
}


@media screen and (max-width: 1000px){
  .footerWrapper{
    .container{
      @include flex-box($dir: column);
      row-gap: 8px;
    }
  }
}

@media screen and (max-width: 750px){
  .footerWrapper{
    .container{
      padding: 16px;
      row-gap: 2px;

      .leftSection{
        column-gap: 16px;

        img{
          height: 24px !important;
        }
      }

      .rightSection{
        column-gap: 12px;
      }
    }
  }
  
  .link, .txt{
    font-size: 14px !important;
  }

  .icon{
    font-size: 16px !important;
    height: 16px;
  }
}

@media screen and (max-width: 600px){
  .footerWrapper{
    .container{
      row-gap: 12px;
      .leftSection{
        flex-direction: column !important;
        row-gap: 12px;
        img{
          height: 14px !important;
        }
      }
      .mailLink{
        flex-direction: column !important;
      }
    }
  }
}