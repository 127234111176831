// white spectrum
$white: #fff;
$white2: #FAFAFA;

// black spectrum
$black: #000;
$black2: #0000000F;
$black3: #211913;

// orange spectrum
$light-orange: #de8c2b1a;
$orange: #D48F27;
$orange2: #FEA252;

// green spectrum
$light-green: #839a511a;
$light-green2: #1CA1291A;
$green: #839A51;
$green2: #1CA129;

// yellow spectrum
$yellow: #FFC845;
$light-yellow1: #FFC8452A;
$light-yellow2: #FFC8453A;

// gray spectrum
$light-gray: #6c6c6c1a;
$dark-gray: #404040;
$gray: #6C6C6C;
$gray2: #7A7A7A;
$gray3: #D9D9D9;
$gray4: #F1F1F1;
$gray5: #E5E5E5;
$gray6: #f1f1f180;
$gray7: #999999;
$gray8: #A9A9A9;

// blue spectrum
$blue: #33BFFF;
$darkBlue: #3361FF;

// purple spectrum
$purple: #8833FF;
$lightPurple: #8833FF1a;
$lightestPurple: #8833FF0D;

// red spectrum
$red: #E62E2E;
$lightRed: #E62E2E1A;
$red2: #D51C27;
$red3: #ED546F;

// notification color spectrum
$alertSuccess: #1CA129;
$alertError: #E62E2E;
$alertInfo: #3361FF;
$alertWarning: #D48F27;

// table header background
$table-header-background: #EFF0E9;