@use "styles/variables" as *;

.select-filter{
  height: 32px;
  @include flex-box;
  background-color: $white;
  border: 1px solid $gray5;
  box-shadow: $bottom-shadow;
  border-radius: 100vmax;

  .popover-style{
    @include popover-style($margin: 18px 0 0 0);
  }

  .filter-label{
    @include filter-option-label;
  }

  .select-button{
    padding: 0 12px;
    @include flex-box;
    cursor: pointer;
    gap: 4px;
    max-width: 200px;

    p{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include poppin-font($color: $gray2);
      color: $gray2;
      span{
        color: $black3;
      }
    }
  }
}