@use 'styles/variables' as *;

.productdetails {
  display: flex;
  gap: 8px;

  .productImgTag{
    width: 52px;
    height: 52px;
    border-radius: 5px;
  }

  .productNameSKUcode {
    display: flex;
    flex-direction: column;

    .skuCode {
      @include poppin-font($weight: $bold6);
      margin-bottom: 3px;
      word-break: break-all;

      .fbvIcon{
        height: 12px;
        margin-left: 4px;
      }
    }
    
    .productName{
      @include poppin-font($color: $gray2, $size: 10px);
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}


.productThumbnailLite{
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  .productImgTag{
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .skuCode {
    @include flex-box($justify: flex-start);
    span{
      @include poppin-font($bold5);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .fbvIcon{
      height: 12px;
      margin-left: 4px;
    }
  }
}