@use 'styles/variables' as *;

.chart-header {
  @include flex-box;
  .chart-header-text-wrapper{
    @include flex-box;
    .chart-header-text{
      font-size: 14px;
      font-weight: $bold6;
    }
    .report-info-icon{
      width: 16px;
      height: 16px;
      font-size: 12px;
      @include flex-box($justify: center);
      margin-left: 8px;
      color: $gray2;
      border: 1px solid $gray2;
      border-radius: 8px;
    }
  }
  .chart-navigation {
    color: #3361FF;
    text-decoration: none;
    @include flex-box;
    font-size: 12px;
    font-weight: 400;
  }
}