@use 'styles/variables' as *;

.dashboard-wrapper{
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: $gray4;
  @include flex-box($dir: column);
}

.charts-container{
  width: 100%;
  padding: 0 24px;
  margin-bottom: 12px;
  overflow-y: scroll;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  gap: 20px;
}

.chart-report-wrapper{
  background-color: $white;
  padding: 16px 20px;
  border-radius: 8px;
}

.load-err-txt{
  width: 100%;
  height: 100%;
  @include flex-box($justify: center);
  text-align: center;
}

.label-value-section{
  .label{
    font-size: 10px;
    color: $gray2;
  }
  .value{
    font-size: 12px;
    font-weight: $bold6;
  }
}

.sub-heading-with-toggle{
  padding: 12px;
  @include flex-box;
  border-radius: 8px;
  border: 1px solid #F1F1F1;
}

.return-exchange-chart{
  & > * {
    margin: 0px 0px 16px 0px !important;
  }
  
  .return-exchange-info-subheader{
    @include flex-box;

    .section{
      @include flex-box;

      .color-code{
        width: 12px;
        aspect-ratio: 1;
        border-radius: 50%;
      }

      p{
        font-size: 12px;
        margin-left: 6px;

        span{
          font-weight: 600;
        }
      }
    }
  }

  .reason-header{
    display: grid;
    grid-template-columns: 20% 30% 10% 20% 20%;

    p{
      color: $gray2;
      font-size: 12px;

      &:first-of-type{
        grid-column: 1 / 3;
      }

      &:nth-of-type(2){
        grid-column: 4 / 5;
      }

      &:nth-of-type(3){
        grid-column: 5 / 6;
      }
    }
  }

  .reasons-list{
    display: grid;
    grid-template-columns: 20% 30% 10% 20% 20%;
    font-size: 12px;
    font-weight: $bold6;
    row-gap: 6px;

    .reason{
      grid-column: 1 / 3;
      font-size: 10px;
      font-weight: $bold4;
    }

    .return-category{
      grid-column: 4 / 5;
    }

    .exchange-category{
      font-weight: $bold6;
    }
  }
}

.rto-summary-chart{
  & > * {
    margin: 0px 0px 16px 0px !important;
  }

  .sub-chart-wrapper{
    @include flex-box($justify: space-around);

    .semi-concentric-chart{
      width: 160px;
      height: 108px;
      position: relative;

      .full-path, .progress-path{
        position:absolute;
        z-index: 2;
      }

      .semi-chart-label, .semi-chart-footer{
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: 12px;
      }

      .semi-chart-label{
        bottom: 0;
      }

      .semi-chart-footer{
        margin: 0 auto;
        bottom: 32px;

        .value{
          font-size: 12px;
          font-weight: $bold6;
        }

        .percentage{
          font-size: 10px;
        }
      }
    }
  }

  .pie-chart-container{
    position: relative;
    width: 100%;
    height: 210px;
    @include flex-box($justify: space-around);

    .round-pie-chart{
      position: relative;
      width: 160px;
      height: 210px;
    }
  }
}

.product-return-summary-chart{
  .product-item-list{
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: 75% 10% 15%;
    row-gap: 8px;

    .order-product-details{
      grid-column: 1 / 2;
      cursor: pointer;
    }
    .product-price{
      font-size: 12px;
      grid-column: 3 / 4;
    }
  }
}

.payment-summary-chart{
  & > * {
    margin: 0px 0px 16px 0px !important;
  }
}

.inventory-chart{
  .data-row{
    margin-top: 16px;
    .data-value{
      color: #E62E2E;
      .hazard-logo{
        margin-left: 6px;
        height: 16px;
      }
    }
  }
}

.lor-chart{
  .data-row{
    margin-top: 16px;
    .data-value{
      color: #E62E2E;
      .hazard-logo{
        margin-left: 6px;
        height: 16px;
      }
    }
  }
}

.open-orders-chart{
  .data-value{
    color: #E62E2E;
    .hazard-logo{
      margin-left: 6px;
      height: 16px;
    }
  }
}

.data-value-brackets{
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
}

.gray-color{
  color: $gray2;
}

.product-sales-sku-info-chart{
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.product-sales-sales-info-chart{
  grid-row: 1 / 2;
  grid-column: 2 / 4;

  .data-link-wrapper{
    position: relative;
    
    .chart-navigation {
      position: absolute;
      right: 0;
      top: 0;
      color: #3361FF;
      text-decoration: none;
      @include flex-box;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.product-sales-complete-info-chart{
  display: none;
  .data-row{
    margin-top: 16px;
  }
}

.fbv-storage-charges-chart{
  & > .data-row{
    margin-top: 16px;
  }
}

.ots-chart{
  grid-row: 2 / 4;
  grid-column: 1 / 2;
}

.open-orders-chart{
  grid-row: 2 / 4;
  grid-column: 2 / 3;
}
.inventory-chart{
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}
.lor-chart{
  grid-row: 3 / 4;
  grid-column: 3 / 4;
}
.return-exchange-chart{
  grid-row: 4 / 6;
  grid-column: 1 / 2;
}
.rto-summary-chart{
  grid-row: 4 / 6;
  grid-column: 2 / 3;
}
.product-return-summary-chart{
  grid-row: 6 / 7;
  grid-column: 1 / 2;
}
.payment-summary-chart{
  grid-row: 4 / 5;
  grid-column: 3 / 4;
}

.fbv-storage-charges-chart{
  grid-row: 5 / 6;
  grid-column: 3 / 4;
}


.load-err-txt{
  width: 100%;
  height: 100%;
  @include flex-box($justify: center);
  text-align: center;
}

.no-data-text {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}


.chart-navigation {
  font-size: 12px;
  font-weight: 400;
}

.chart-total_percent {
  @include flex-box;  
  margin-top: 18px;
  & > div{
    @include flex-box;
    .color-code{  
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 6px;
      &.seller-color-code{
        background-color: $blue;
      }
      &.fbv-color-code{
        background-color: $orange2;
      }
    }
    & > p{
      font-size: 12px;
      b{
        font-weight: 600;
      }
    }
  }
}

.no-ots-data-txt{
  margin-top: 18px;
  font-size: 12px;
  color: $gray2;
}



.data-row-wrapper{
  width: 100%;
  border-radius: 12px;
  background-color: $gray6;
  padding: 12px;
  margin-top: 16px;
  .data-row-heading{
    color: $gray2;
    font-size: 12px;
    margin-bottom: 8px;
  }
}

.report-info {
  display: inline;
  border-radius: 25px;
  padding: 1px 5px;
  border: 1px solid black;
  font-size: 12px !important;
}

.toggle-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  width: max-content;
}
.toggle-section .options{
  font-size: 12px;
}

@media screen and (max-width: 1200px){
  .charts-container{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto auto auto;
  }
  .product-sales-sku-info-chart {
    display: none;
  }
  .product-sales-sales-info-chart{
    display: none;
  }
  .product-sales-complete-info-chart{
    display: block;
    grid-row: 2 / 4;
    grid-column: 1 / 2;
  }
  .ots-chart{
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  .open-orders-chart{
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  
  .inventory-chart{
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  .lor-chart{
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }
  .return-exchange-chart{
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }
  .rto-summary-chart{
    grid-row: 4 / 5;
    grid-column: 2 / 3;
  }
  .product-return-summary-chart{
    grid-row: 5 / 7;
    grid-column: 2 / 3;
  }
  .payment-summary-chart{
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }
  .fbv-storage-charges-chart{
    grid-row: 6 / 7;
    grid-column: 1 / 2;
  }
}

@media screen and (max-width: 600px){
  .dashboard-wrapper{
    padding-top: 0;
  }
  .charts-container{
    display: flex;
    flex-direction: column;
    padding: 0;

    .chart-report-wrapper{
      border-radius: 0;
    }
  }
}