@use 'styles/variables' as *;
@import 'styles/classes';

p{
  margin: 0;
}
.wrapper{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: $forge-header-height;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  background-color: #fff !important;
  padding: 0 32px;
  border-bottom: 1px solid $gray4;

  .brandInfo{
    display: flex;
    align-items: center;
    height: 32px;

    .forgeIcon{
      height: 100%;
    }
  }

  .userInfo{
    display: flex;
    align-items: center;
    height: 32px;

    .supplierLogo{
      height: 100%;
    }

    .userIcon{
      cursor: pointer;
      margin-left: 16px;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f1f1f1;
      border-radius: 20px;
    }

    .userIcon > *{
      margin: 0 2px;
      color: $black3;
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper{
    padding: 0 16px;
  }
}

.wrapper{
  @include popover-style($margin: 18px 0 0 0);
}

.actionButton{
  @include poppin-font;
  cursor: pointer;
  width: max-content;
  padding: 8px 12px;
  border-bottom: 1px solid $gray4;

  &:last-of-type{
    border-bottom: none;
  }
}

@media screen and (max-width: 600px){
  .supplierLogo{
    max-width: 80px;
    max-height: 24px;
  }
}