.popover-content{
  display: flex;
  width: fit-content;
  .progress-item{
    position: relative;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .label{
      padding-bottom: 8px;
      font-size: 15px;
    }
    .value{
      padding-top: 8px;
      font-size: 15px;
    }
    .step-col{
      position: absolute;
      width: 4px;
      border-radius: 2px;
      height: 16px;
      left: calc(50% - 2px);
      top: calc(50% - 8px);
      background-color: #FECC01;
    }
    .step-bar{
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: #FECC01;
      top: calc(50% - 2px);
    }
  }
  .progress-item:first-of-type{
    .step-bar{
      width: 50%;
      left: 50%;
    }
  }
  .progress-item:last-of-type{
    .step-bar{
      left: 0;
      width: 50%;
    }
  }
}

.long-width{
  max-width: none !important;
}