@use "styles/variables" as *;

.multi-step-progress-bar{
  width: 100%;
  border-radius: 100vmax;
  @include flex-box;
  overflow: hidden;
  .progress-step{
    height: 100%;
    &:first-of-type{
      border-top-left-radius: 100vmax;
      border-bottom-left-radius: 100vmax;
    }
    &:last-of-type{
      border-top-right-radius: 100vmax;
      border-bottom-right-radius: 100vmax;
    }
  }
}