@use 'styles/variables' as *;

.toggle{
  height: 27px;
  border-radius: 100vmax;
  padding: 4px;
  background-color: $white2;
  border: 1px solid $white;
  @include flex-box;
  column-gap: 4px;
  width: max-content;

  .true-label, .false-label{
    height: 100%;
    border-radius: 100vmax;
    padding: 0 8px;
    cursor: pointer;

    .label{
      font-size: 12px;
      color: $dark-gray;
    }

    &.selected{
      background: $white;
      box-shadow: 2px 4px 12px 0px $black2;

      .label{
        color: $purple;
        font-weight: $bold6;
      }
    }
  }
}