@use 'styles/variables' as *;
@import 'styles/report';


.reportWrapper{
  & > :first-child{
    margin-top: 0 !important;
  }
}

.pickpackStorageSummaryWrapper{
  width: calc(100% - 2 * 24px);
  margin: 0 24px 12px 24px;
  order: -1;

  .pickpack-storage-summary{
    padding: 20px 16px;
    background-color: $white;
    box-shadow: 0px 1px 15px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 10px;
  
    .individual-fee {
      @include flex-box($dir: column-reverse, $align: unset);
      row-gap: 6px;

      .fee-value {
        font-size: 14px;
        margin-bottom: 0 !important;
      }
    }
  }
}

.invoiceDownload {
  flex-shrink: 0 !important;
  padding: 0 18px !important;
  border-radius: 6px !important;
  height: 28px !important;

  color: $white !important;
  font-weight: $bold6 !important;
  font-size: 12px !important;
  background-color: #3361ff !important;
  text-transform: none !important;
  display: flex;
  column-gap: 8px;

  &:hover {
    background-color: #2e58e8;
  }

  img {
    max-width: 16px;
    color: $white;
  }
}

@media screen and (max-width: 600px) {
  .pickpackStorageSummaryWrapper {
    flex-shrink: 0;
    order: 0;
    width: 100%;
    margin: 0 0 12px 0;
    padding: 1px 24px 1px 24px;
    overflow-x: scroll;

    .pickpack-storage-summary {
      width: max-content;

      .individual-fee {
        flex-basis: unset !important;
        flex-grow: unset !important;
        width: max-content;
      }
    }
  }
}


.chargeData {
  @include flex-box;
  @include poppin-font();
  column-gap: 8px;
  padding: 12px 0;

  .chargeLabel {
    color: $gray2;
  }
  .chargeVal {
    font-weight: $bold6;
  }
}

.chargesWrapper {
  width: calc(100% - 2 * 24px);
  margin-bottom: 12px;
  gap: 12px !important;

  .chargeData {
    column-gap: 8px;
    padding: 6px 0;
  
    .chargeLabel {
      font-size: 10px;
      color: $gray2;
    }
    .chargeVal {
      font-size: 10px;
      font-weight: $bold6;
    }
  }
}

.popupWrapper {
  z-index: 2000;
  @include popover-style($margin: 20px 0 20px 0, $padding: 3px);

  .value {
    margin: 0 auto;
    width: max-content;
    cursor: pointer;
    text-decoration: underline;
  }
}


.totalChargeDetails{
  width: calc(100% - 2 * 24px);
  margin: 24px 0 12px 0;
  @include flex-box;
  order: -1;

  p{
    @include poppin-font($size: 20px, $weight: $bold6);
  }
}

@media screen and (max-width: 600px) {
  .totalChargeDetails{
    order: 0;
    margin: 0 0 12px 0;
    p{
      @include poppin-font($color: $gray2, $weight: $bold4);
    }
  }
}