@use './variables' as *;

.reportWrapper{
  width: 100%;
  height: 100%;
  background-color: $gray4;
  @include flex-box($dir: column, $justify: flex-start);

  .pageTabsWrapper{
    width: 100%;
    margin-bottom: 12px;
    padding: 0 24px;
    :global(.MuiTabs-root){
      min-height: unset !important;
    }
    button{
      min-height: unset !important;
      max-height: 36px !important;
    }
  }

  .filterAndDownloadWrapper{
    width: 100%;
    @include flex-box($align: unset, $justify: unset);
    column-gap: 24px;
    margin-bottom: 12px;
    padding: 0 24px;

    .filtersWrapper{
      margin-right: auto;
      @include flex-box($align: unset, $wrap: wrap, $justify: unset);
      gap: 12px;
  
      .checkboxTxt{
        @include poppin-font;
      }
    }

    .downloadBtn{
      flex-shrink: 0;
      padding: 0 18px;
      border-radius: 6px;
      height: 28px;
      @include poppin-font($weight: 600, $color: $white);
      background-color: #3361FF;
      text-transform: none;
      &:hover{
        background-color: #2E58E8;
      }
    }

    .downloadFAB{
      position: fixed;
      bottom: 64px;
      right: 16px;
      background-color: $darkBlue;

      & > *{
        color: $white;
      }
    }

    .filterFAB{
      position: fixed;
      bottom: 64px;
      padding: 8px 16px;
      background-color: $white;
      left: 50%;
      transform: translateX(-50%);
      text-transform: none;
      @include poppin-font($weight: 600);
    }
  }

  .orderAWBDetails {
    @include flex-box;
    column-gap: 16px;
  
    .awbStatus{
      .awbNumber {
        color: #7A7A7A;
        text-decoration: underline;
      }
    }
  
    .orderStatus{
      color: #3361FF;
    }
  }
  
}

@media screen and (max-width: 600px) {
  
}