@use 'styles/variables' as *;

.swipeable-mobile-drawer{
  padding: 24px 16px;
  width: min(480px, 100vw);
  min-height: 50dvh;
  max-height: 80dvh;
  margin: 0 auto;
  @include flex-box($dir: column, $align: center);

  .filters-section{
    width: 100%;
    @include flex-box;

    .filter-type{
      width: 40%;
      @include flex-box($dir: column);
      border-right: 1px solid $gray5;
      padding-right: 16px;
      row-gap: 80px;

      .select-type-filter, .checkbox-type-filter{
        @include flex-box($dir: column);
        row-gap: 8px;
        width: 100%;
      }

      .filter-item{
        width: 100%;
        border-radius: 9px;
        padding: 8px 12px;
        font-size: 12px;

        &.selected{
          background: #8833FF1A;
          @include poppin-font($weight: 600, $color: $purple);
        }

        &.checkbox-item{
          background-color: $white;
          border: 1px solid $gray5;
          @include flex-box;

          .checkbox{
            color: $gray3;
          }

          &.selected{
            border: 1px solid $purple;
            .checkbox{
              color: $purple;
            }
          }
        }
      }
    }

    .filter-options{
      align-self: stretch;
      width: 60%;
      padding-left: 16px;

      .filter-label{
        @include poppin-font($weight: 600, $size: 14px);
        margin-bottom: 8px;
      }
      .selected-options{
        width: 100%;
        padding: 8px;

        .option{
          @include flex-box($justify: flex-start);
          column-gap: 8px;

          .checkbox{
            color: $gray3;
            padding: 4px;
          }

          .label{
            @include poppin-font;
          }

          &.selected{
            .checkbox{
              color: $purple;
            }
          }
        }
        .radio-label{
          :global(.MuiFormControlLabel-label){
            @include poppin-font;
          }
        }
        .radio-button{
          color: $gray3;
          padding: 4px;
          &:global(.Mui-checked){
            color: $purple;
          }
        }
      }
    }
  }
}

.picker-footer{
  width: 100%;
  margin-top: 16px;
  @include flex-box($justify: flex-end);
  column-gap: 16px;

  .submit-button{
    flex-basis: 0;
    flex-grow: 1;
  }
  
  .submit-button, .clear-button{
    padding: 0 24px;
    border-radius: 8px;
    height: 40px;
  }

  .clear-button{
    border: 1px solid $black3;
    &:hover{
      border-color: 1px solid $black;
    }
  }
}