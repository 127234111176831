@use "styles/variables" as *;

.fileUploadModal{
  padding: 32px;
  border-radius: 20px;
  width: 520px;
  @include flex-box($dir: column, $align: unset);
  row-gap: 20px;

  .label{
    @include poppin-font($size: 14px, $weight: $bold6);
  }

  .sampleFile{
    width: max-content;
    padding: 4px 12px;
    @include flex-box;
    gap: 20px;
    background-color: $gray4;
    border-radius: 100vmax;
    
    .defaultTxt{
      @include poppin-font();
    }

    .downloadSampleIcon{
      cursor: pointer;
      @include flex-box;
      @include poppin-font($weight: $bold6, $color: $darkBlue);
    }
  }

  .uploadedFiles{
    width: 100%;
    @include flex-box($dir: column);
    gap: 12px;

    .fileItem{
      width: 100%;
      background-color: $gray4;
      border-radius: 8px;
      padding: 12px;
      @include flex-box;

      img{
        width: 52px;
        height: 52px;
        object-fit: cover;
      }

      .fileInfo{
        margin-right: auto;
        margin-left: 12px;
        p{
          @include poppin-font();
        }
      }

      .deleteIcon{
        cursor: pointer;
        margin-left: 12px;
      }
    }
  }

  .dragArea{
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    border: 1px dashed $gray8;
    @include flex-box($dir: column, $justify: center);
    gap: 16px;

    &.withDrag{
      background-color: $gray5;
    }

    .uploadDefaultTxt{
      @include poppin-font($size: 14px);

      span{
        color: $darkBlue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .actionBtns{
    width: 100%;
    margin-top: 16px;
    @include flex-box($justify: flex-end);
    
    .submit-button, .clear-button{
      padding: 0 24px;
      margin-left: 16px;
      border-radius: 8px;
      height: 40px;
    }

    .submit-button{
      flex-grow: 1;

      &:disabled{
        opacity: 0.4;
        color: $white;
      }
    }

    .clear-button{
      border: 1px solid $black3;
      &:hover{
        border-color: 1px solid $black;
      }
    }
  }
}


@media screen and (max-width: 560px) {
  .fileUploadModal{
    width: 90vw;
    padding: 24px;
  }
}