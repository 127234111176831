.loaderPaper{
  width: calc(100% - 48px);
  overflow: hidden;
  border-radius: 10px !important;
  margin: 0 24px;
  
  img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}


@media screen and (max-width: 600px){
  .loaderPaper{
    width: 100%;
    margin: 0;
  }
}