@use 'styles/variables' as *;
@import 'styles/report';

.ontimePill {
  padding: 5px 8px 5px 8px;
  background: #839A511A;
  color: #839A51;
  border-radius: 10px;
  font-weight: $bold6;
}

.delayedPill {
  padding: 5px 8px 5px 8px;
  background: #ED546F1A;
  color: #ED546F;
  border-radius: 10px;
  font-weight: $bold6;
}