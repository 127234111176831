@use 'styles/variables' as *;

.swipeableMobileDrawer{
  padding: 24px 16px;
  width: min(480px, 100vw);
  margin: 0 auto;

  .content{
    width: 100%;
    
    .progressItem{
      height: 36px;
      @include flex-box($justify: flex-start);

      .line{
        position: relative;
        height: 100%;
        aspect-ratio: 1;
        @include flex-box($justify: center);
        
        .stepDot{
          z-index: 4;
          width: 12px;
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: $gray3;
        }

        .stepCol{
          position: absolute;
          top: -50%;
          left: calc(50% - 1px);
          width: 2px;
          height: 100%;
          background-color: $gray3;
        }

        &.hasData{
          .stepDot, .stepCol{
            background-color: $green2;
          }
        }
      }
    
      .progressTxt{
        height: 100%;
        @include flex-box($dir: column, $align: flex-basis, $justify: center);
        row-gap: 4px;
        
        .label{
          @include poppin-font($size: 10px, $color: $gray2);
          line-height: 10px;
        }
        .value{
          @include poppin-font($size: 12px, $weight: $bold6);
          line-height: 12px;
        }
      }  
    }
  }  
}