@use './color' as *;

// font-weights
$bold1: 100;
$bold2: 200;
$bold3: 300;
$bold4: 400;
$bold5: 500;
$bold6: 600;
$bold7: 700;
$bold8: 800;
$bold9: 900;