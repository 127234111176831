@use 'styles/variables' as *;


@mixin selection-col-mixin(){
  position: sticky;
  left: 0;
  width: $selection-col-width;
  min-width: $selection-col-width;
  max-width: $selection-col-width;
  box-shadow: 2px 4px 12px 0px #0000000F;
  text-align: center;
}

@mixin hidden-relative-col-mixin(){
  position: sticky;
  box-shadow: 2px 4px 12px 0px #0000000F;
}

.tableWrapper{
  @include flex-box($dir: column, $align: unset);
  margin: 0 24px 12px 24px;
  width: calc(100% - 2 * 24px);
  overflow: hidden;
  border-radius: 10px !important;
  flex-grow: 1;

  .actionBar{
    width: 100%;
    min-height: 40px;
    flex-shrink: 0;
    border-bottom: 1px solid $gray5;
  }

  .pagination{
    :global(.MuiTablePagination-toolbar){
      background-color: $white2;
      border-bottom: 1px solid $gray5;
      min-height: 40px !important;
      font-size: 12px !important;

      :global(.MuiTablePagination-selectLabel){
        font-size: 12px;
      }
      :global(.MuiTablePagination-input){
        margin: 0 8px;
      }
      :global(.MuiTablePagination-displayedRows){
        font-size: 12px;
      }
      :global(.MuiTablePagination-actions){
        margin-left: 8px !important;
      }
    }
  }
  

  .tableContainer {
    height: 100%;

    th, td{
      font-size: 12px !important;
      font-weight: 400 !important;

      .checkbox{
        padding: 0;
        color: transparent;
        background: $white;
        border-radius: 2px;
        width: 14px;
        height: 14px ;
        border: 1px solid $gray2 ;
      
        &.selected{
          color: $purple;
        }
      }
    }

    thead{
      th{
        padding: 16px 16px 16px 0;
        background-color: $table-header-background;

        &.fixedCol{
          @include selection-col-mixin;
          padding: 16px !important;
          z-index: 8;
        }

        &.hiddenRelativeCol{
          @include hidden-relative-col-mixin;
          padding: 16px !important;
          z-index: 8;
        }
      }
    }

    tbody{
      tr{
        td{
          padding: 8px 16px 8px 0;
          background-color: $white;

          &.fixedCol{
            @include selection-col-mixin;
            padding: 8px 16px !important;
            z-index: 6;
          }

          &.hiddenRelativeCol{
            @include hidden-relative-col-mixin;
            padding: 8px 16px !important;
            z-index: 6;
          }
        }
      }
    }

    tr:has(td:hover){
      background-color: $gray4 !important;

      td{
        background-color: $gray4 !important;
      }
    }

    &.withFixedColumn{
      &.hideFixedCol tbody td.hiddenRelativeCol, &.hideFixedCol thead th.hiddenRelativeCol{
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px){
  .tableWrapper{
    margin: 0;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 0px !important;
  }
}
