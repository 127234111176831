@use 'styles/variables' as *;

@mixin status-based-alert($status, $baseColor){
  :global(.MuiAlert-filled#{$status}){
    background: linear-gradient(0deg, #{$baseColor}1A, #{$baseColor}1A), linear-gradient(0deg, $white, $white);
    border-left: 2px solid $baseColor;
    color: $baseColor !important;
  }
}

.snackbar{
  max-width: 464px;

  .alert{
    width: 100%;
    @include flex-box($justify: unset);

    :global(.MuiAlert-action){
      padding: 0 0 0 16px !important;
    }

    :global(.MuiAlert-message){
      font-weight: $bold6;
    }
  }

  @include status-based-alert('Success', $alertSuccess);
  @include status-based-alert('Error', $alertError);
  @include status-based-alert('Info', $alertInfo);
  @include status-based-alert('Warning', $alertWarning);
}