@use "styles/variables" as *;

.txt-input{
  margin-bottom: 24px !important;

  input:disabled, textarea:disabled{
    cursor: not-allowed;
  }
}

.get-help-modal{
  width: calc(456px + 2 * 32px);
  padding: 32px;
  border-radius: 20px;
}

.drawer{
  :global(.MuiDrawer-paperAnchorBottom){
    border-radius: 0 !important;
  }

  .swipeable-mobile-drawer{
    height: calc(100dvh - 56px);
    padding: 24px 16px 0 16px;
    max-width: 360px;
    margin: 0 auto;
    @include flex-box($dir: column, $align: center, $justify: unset);
    overflow-y: scroll;
    background-color: red;
  }
}

.get-help-header{
  width: 100%;
  margin-bottom: 24px;
  font-weight: $bold6;
}


.select-option{
  padding: 8px 12px !important;
  font-size: 12px !important;
  border-bottom: 1px solid $gray4 !important;
}

.gethelp-form{
  width: 100%;

  .image-upload{
    width: 100%;
    margin-bottom: 24px;

    .image-upload-label{
      font-size: 14px;
      margin-bottom: 8px;

      span{
        color: #A9A9A9;
        font-size: 12px;
      }
    }

    .image-list{
      width: 100%;

      @include flex-box($wrap: wrap, $justify: unset);
      gap: 12px;

      .image-list-item{
        position: relative;
        width: 100px;
        height: 100px;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }

        .delete-btn{
          position: absolute;
          z-index: 2;
          top: 2px;
          right: 2px;
          color: black;
          background-color: #ffffffbf;
          padding: 6px;
        }
      }

      .upload-btn{
        width: 100px;
        height: 100px;
        border-radius: 8px;
        background-color: $white2;
        border: 1px solid $gray5;
        @include flex-box;
        cursor: pointer;

        svg{
          margin: auto;
          color: $gray7;
        }
      }
    }
  }
}

.get-help-footer{
  width: 100%;
  margin-top: auto;
  @include flex-box($justify: flex-end);
  
  .submit-button, .discard-button{
    padding: 0 24px;
    border-radius: 8px;
    height: 40px;
  }

  .submit-button{
    flex-grow: 1;
    margin-left: 16px;

    &:disabled{
      color: $white;
      background-color: #bbc7fb;
    }
  }

  .discard-button{
    border: 1px solid $black3;
    &:hover{
      border-color: 1px solid $black;
    }
  }
}

@media screen and (max-width: 600px){
  .get-help-footer{
    margin-bottom: 24px;
  }
}