@use "./color" as *;
@use  "./font" as *;
@use "./shadow" as *;

@mixin flex-box($dir: row, $align: center, $justify: space-between, $wrap: nowrap){
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@mixin poppin-font($weight: 400, $style: normal, $size: 12px, $color: $black3){
  font-family: "Poppins", sans-serif;
  font-weight: $weight;
  font-style: $style; // normal | italic
  font-size: $size;
  color: $color;
}

@mixin popover-style($margin: 0, $padding: 0, $border: 1px solid $gray5, $borderRadius: 8px, $boxShadow: $popover-shadow){
  :global(.MuiTooltip-tooltip){
    margin: $margin !important;
    padding: $padding !important;
    border: $border !important;
    border-radius: $borderRadius !important;
    box-shadow: $boxShadow !important;
  }
}

@mixin filter-options-section{
  .options-section-wrapper{
    .options-wrapper{
      max-height: 400px;
      overflow-y: scroll;
      scrollbar-width: thin;
      ::-webkit-scrollbar {
        width: 8px;
      }
    }
  }
}

@mixin filter-option-label{
  @include flex-box($justify: flex-start);
  column-gap: 6px;
  border-bottom: 1px solid $gray4;
  min-width: 144px;
  padding: 12px;
  font-weight: $bold4;
  cursor: pointer;

  .checkbox{
    color: $gray3;
    padding: 0px;
  }

  &:last-of-type{
    border-bottom: none;
  }

  &:hover{
    color: $purple;
    background-color: $lightestPurple;
    .checkbox{
      color: $purple;
    }
  }

  &.selected{
    
    .checkbox{
      color: $purple;
    }
  }
}