@use 'styles/variables' as *;
@import 'styles/report';

.user-gst-info{
  .gst-user{
    font-size: 12px;
    color: $gray2;
    line-height: 16px;
    margin: 0 !important;
  }
  .gst-number{
    font-size: 12px;
    color: $dark-gray;
    font-weight: $bold6;
    margin: 0 !important;
  }
}
.cell-row-2{
  font-size: 11px;
  color: $gray2;
}