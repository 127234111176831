@use 'styles/variables' as *;
@import 'styles/report';

.tooltipStyle{
  @include popover-style($padding: 8px 12px, $margin: 15px 0 0 0);
}

.grnListItem{
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: 1;
  padding: 4px 0;
  border-bottom: 1px solid $gray5;

  &:last-of-type{
    border-bottom: none;
  }

  .receivedAt{
    grid-column: 1 / 2;
    padding-right: 24px;
    @include poppin-font($color: $gray2);
  }

  .quantity{
    grid-column: 2 / 3;
    padding-right: 24px;
    @include poppin-font($weight: $bold6);
  }
}

.messageBox{
  width: min(400px, 100vw - 32px);
  padding: 32px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0px 30px 60px 0px #0000001F;
  border-radius: 20px;

  .message{
    @include poppin-font($size: 14px, $weight: $bold6);
  }
  .mailLabel{
    margin-top: 24px;
    @include poppin-font($size: 16px, $color: $gray2);
  }
  .mailLink{
    @include poppin-font($size: 16px, $weight: $bold6, $color: $darkBlue);
    text-decoration: none;
  }
}

@media screen and (max-width: 600px) {
  .messageBox{
    padding: 24px;
    border-radius: 16px;
  }
}
