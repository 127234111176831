@use "styles/variables" as *;


.picker-modal{
  padding: 32px;
  border-radius: 20px;
}

.swipeable-mobile-drawer{
  padding: 24px 16px;
  max-width: 360px;
  margin: 0 auto;
  @include flex-box($dir: column, $align: center);

  .dateRangePickerWrapper{
    width: 100%;
    @include flex-box($dir: column-reverse, $align: center);

    :global(.rdrDefinedRangesWrapper){
      border-right: none;
      margin-top: 12px;
      width: 100%;
      
      :global(.rdrStaticRanges){
        @include flex-box($wrap: wrap, $align: initial, $justify: flex-start);
        gap: 8px;

        :global(.rdrStaticRange){
          border: none;

          :global(.rdrStaticRangeLabel){
            border: 1px solid $gray5;
            border-radius: 8px;
            padding: 6px 16px;
            @include poppin-font;
          }
        }
      }
      :global(.rdrStaticRangeSelected){
        :global(.rdrStaticRangeLabel){
          color: $purple !important;
          border-color: $purple !important;
          background-color: $white !important;
        }
      }
    }
  }
}

.picker-header{
  width: 100%;
  margin-bottom: 24px;
}
.picker-footer{
  // width: 100%;
  margin-top: 16px;
  @include flex-box($justify: flex-end);
  
  .submit-button, .clear-button{
    padding: 0 24px;
    margin-left: 16px;
    border-radius: 8px;
    height: 40px;
  }

  .clear-button{
    border: 1px solid $black3;
    &:hover{
      border-color: 1px solid $black;
    }
  }
}


.dateRangeLabel{
  @include flex-box;
  column-gap: 6px;
  color: $black3;;
  background-color: $white;
  border-radius: 100vmax;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid $gray5;

  .icon{
    font-size: 14px;
  }

  p{
    @include poppin-font;

    &:nth-of-type(2){
      @include poppin-font($size: 8px, $color: $gray2);
    }
  }
}

.dateFooterWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customDateInput{
  margin-top: 16px !important;
  width: 100%;
  margin-right: 10px !important;
}

.customDateInputWrapperMobile{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

@media screen and (max-width: 600px){
  .dateRangeLabel{
    border: none;
    padding: 3px 6px;
    background-color: transparent;
  }
  .customDateInput{
    margin-top: 8px !important;
    width: 100%;
    margin-right: 0px !important;
  }
}