@use "styles/variables" as *;

.data-row {
  @include flex-box($align: start);
  gap: 20px;

  .data-column{
    flex-basis: 0;
    flex-grow: 1;

    .data-value{
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .data-label{
      font-size: 12px;
      color: $gray2;
    }
  }

  .vertical-line{
    width: 1px;
    background-color: $gray5;
    align-self: stretch;
  }
}