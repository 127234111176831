@use "styles/variables" as *;

.calendarWrapper {
  padding: 32px;
  width: 400px;

  .yearSelection {
    width: 100%;
    @include flex-box($justify: center);
    column-gap: 32px;
    margin-bottom: 16px;

    svg {
      cursor: pointer;
    }

    .currYear {
      @include poppin-font($size: 14px, $weight: $bold6);
    }
  }

  .monthSelection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 12px;
    margin-bottom: 24px;

    .monthBtn {
      grid-row: span 1;
      grid-column: span 1;

      &:disabled {
        opacity: 0.8;
        background-color: $gray4;
      }

      &.selected {
        color: $purple;

        border-color: $purple;
      }
    }
  }

  .picker-footer {
    width: 100%;
    margin-top: 16px;
    @include flex-box($justify: flex-end);
    column-gap: 16px;

    .submit-button {
      flex-basis: 0;
      flex-grow: 1;

      &:disabled {
        color: $white;
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .submit-button,
    .clear-button {
      padding: 0 24px;
      border-radius: 8px;
      height: 40px;
    }

    .clear-button {
      border: 1px solid $black3;
      &:hover {
        border-color: 1px solid $black;
      }
    }
  }
}

.popover-style {
  :global(.MuiTooltip-tooltip) {
    border-radius: 20px !important;
  }
}
