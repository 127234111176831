@use 'styles/variables' as *;

.checkboxWrapper{
  @include flex-box;
  column-gap: 8px;

  & > *{
    max-height: 16px !important;
  }

  .checkbox{
    padding: 0;
    color: transparent;
    background: white;
    border-radius: 2px;
    width: 14px;
    height: 14px;
    border: 1px solid $gray2;
  }

  &.selected{
    .checkbox{
      color: $purple;
    }
  }
}