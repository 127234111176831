@use 'styles/variables' as *;

.txtInput{
  width: 100% !important;
  height: 42px !important;
  color: #D9D9D9 !important;
  margin-bottom: 40px !important;
}

.link{
  color: $darkBlue  !important;
  text-decoration: none !important;
}

.actionBtn{
  width: 100%;
  margin-top: 32px !important;
  height: 48px;

  color: $black3 !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-weight: $bold6 !important;
  background-color: $yellow !important;
  border-radius: 12px !important;

  &:hover{
    background-color: #D3B25A !important;
  }
}

.authWrapper{
  width: 100dvw;
  height: 100dvh;
  @include flex-box($dir: column);

  .mainContent{
    width: 100%;
    max-width: 1200px;
    flex-basis: 0;
    flex-grow: 1;
    padding: 16px 24px;
    @include flex-box;

    .bannerSection{
      max-width: 540px;

      .headerBanner{
        max-width: 70%;
      }
      .heroBanner{
        max-width: 100%;
      }
    }

    .inputSection{
      flex-shrink: 0;
      $width: 520px;
      width: $width;
      padding: calc(0.20 * $width) calc(0.15 * $width);
      @include flex-box($dir: column, $justify: center);
      border-radius: 32px;
      background: radial-gradient(circle at top right, $light-yellow2 0%, $light-yellow1 20%, #FFF 40%);
      box-shadow: 0px 4px 26px 0px #0000001F;
      

      .inputForm{
        width: 100%;

        .heading{
          @include poppin-font($weight: 600, $size: 40px);
        }
        .subHeading{
          margin: 8px 0 32px 0;
          @include poppin-font($color: $gray2, $size: 20px);
        }

        .forgotPassTxt{
          text-align: right;
          margin-left: auto;
          @include poppin-font($size: 14px , $color: $gray2);
        }

        .otpSection{
          width: 100%;
          @include flex-box;
          .basicTxt{
            font-size: 14px !important;
            color: $gray2 !important; 
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  // approx: 80%
  .authWrapper{
    .mainContent{
      .bannerSection{
        max-width: 432px;
      }
      .inputSection{
        $width: 420px;
        width: $width;
        padding: calc(0.20 * $width) calc(0.15 * $width);
        border-radius: 25px;

        .inputForm{
          .heading{
            font-size: 32px;
          }
          .subHeading{
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  // approx: 65%
  .authWrapper{
    .mainContent{
      .bannerSection{
        max-width: 351px;
      }
      .inputSection{
        $width: 338px;
        border-radius: 20px;
        width: $width;
        padding: calc(0.20 * $width) calc(0.15 * $width);
        .inputForm{
          .inputForm{
            .heading{
              font-size: 26px;
            }
            .subHeading{
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media screen and  (max-width: 750px){
  // approx: 50%
  .authWrapper{
    .mainContent{
      .bannerSection{
        max-width: 270px;
      }
      .inputSection{
        width: 260px;
        border-radius: 16px;
        padding: 48px 24px;
      }
    }
  }
}

@media screen and  (max-width: 600px){
  .authWrapper{
    .mainContent{
      flex-direction: column;
      justify-content: space-between;
      row-gap: 32px;
      padding: 64px 0 0 0;
      .bannerSection{
        max-width: 60%;
        .headerBanner{
          max-width: 100%;
        }
      }
      .inputSection{
        width: 100%;
        border-radius: 16px 16px 0 0;
        padding: 32px 0 !important;

        .inputForm{
          max-width: 300px;

          .heading{
            font-size: 24px;
          }

          .subHeading{
            font-size: 12px;
          }
        }
      }
    }
  }
}