@use 'styles/variables' as *;

.nav-sidebar-wrapper{
  width: 100%;
  height: 100%;
  background-color: black;
  
  .sidebar-link-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
  }

  .collapse-sidebar-link-list{
    position: relative;
    overflow: auto;
    max-height: 300px;
    padding-right: 6px;
  }
}

.bottom-btn-wrapper {
  margin-top: auto;
}

.sidebar-link {
  color: #d9d9d9;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &.collapse-trigger{
    cursor: pointer;
  }

  .fbv-icon{
    height: 12px;
    margin-left: 6px;
    vertical-align: middle;
  }

  &:hover{
    background-color: #FFFFFF1A;
    border-radius: 0 12px 12px 0;
  }

  &.logout-btn{
    margin-top: auto;
    cursor: pointer;
  }

  .link-text{
    span{
      font-size: 14px !important;
    }
    &.sub-link{
      padding-left: 8px;
    }
  }

  &:hover{
    background-color: #FFFFFF1A;
    border-radius: 0 12px 12px 0;
  }

  &.selected{
    color: $black3;
    background-color: $yellow;
    border-radius: 0 12px 12px 0;

    .link-text{
      span{
        font-weight: 600 !important;
      }
    }

    &:hover{
      background-color: #D3B25A; 
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
