@use 'styles/variables' as *;
@import 'styles/report';

.removeAction{
  @include poppin-font($weight: $bold6, $color: $red);
  cursor: pointer;

  &.disabled{
    color: $gray2;
    cursor: not-allowed;
  }
}


.bulkRemoveBtn{
  font-weight: $bold6 !important;
  font-size: 12px !important;
  flex-shrink: 0;
  height: max-content;
}

.confirmationModal{
  width: min(90vw, 400px);
  padding: 32px;
  border-radius: 20px;

  .modalContent{
    width: 100%;

    .txt{
      @include poppin-font($size: 14px, $weight: $bold6);
    }

    .confirmFooter{
      width: 100%;
      margin-top: 24px;
      @include flex-box($justify: flex-end);
      
      .submitBtn, .discardBtn{
        padding: 0 24px;
        border-radius: 8px;
        height: 40px;
      }
    
      .submitBtn{
        margin-left: 16px;
      }
    
      .discardBtn{
        border: 1px solid $black3;
        &:hover{
          border-color: 1px solid $black;
        }
      }
    }
  }
}


.actionBarContent{
  width: 100%;
  height: 100%;
  background-color: $white2;
  @include flex-box($dir: row-reverse);
  padding-right: 16px;

  .removeBtn{
    @include poppin-font($weight: 600, $size: 10px, $color: $white);
    line-height: 10px;
    text-transform: none;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: $darkBlue !important;
  }
}