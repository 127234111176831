@use 'styles/variables' as *;

.wrapper{
  width: calc(280px + 2 * 10px);
  background-color: $white;
  padding: 10px;
  height: max-content;

  .monthStr{
    @include poppin-font($color: $gray2);
    text-align: center;
  }

  .weekDays{
    width: 100%;
    @include flex-box;
    height: 32px;
    margin-top: 8px;

    .dayTxt{
      @include poppin-font($color: $gray2);
      text-align: center;
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  .daysTable{
    margin-top: 6px;
    width: 100%;

    .weekRow{
      width: 100%;
      @include flex-box;
      border-bottom: 1px solid $gray4;

      &:last-of-type{
        border-bottom: none;
      }

      .dateCell{
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        padding: 6px;
        @include flex-box($dir: column);
        row-gap: 3px;

        .date{
          @include poppin-font;
        }

        .value{
          @include poppin-font($size: 9px);
          color: #1CA129;
        }
      }
    }
  }
}

